<template>
  <el-main class="simple1-container">
    <section class="inner-container">
      <div class="section-h2">
        <MainContain />
        全民姿態評估GO
      </div>
      <p>2024年3月</p>
      <div class="image-container">
        <img :src="image" class="image" alt="體感姿態評估檢測 客製化健促課程">
        <p class="image_desc">體感姿態評估檢測 客製化健促課程</p>
        <p class="image_desc">圖片為全民姿態評估GO活動宣傳圖片</p>
        <p class="image_desc">(圖片提供：優力勁聯)</p>
      </div>
      <div class="content-section">
        <div class="section-h3">實證目標</div>
        <div class="content-desc">
          根據111年教育部體育署針對台灣運動現況的調查結果顯示：
        </div>
        <div class="content-desc">
          因應疫情解封，民眾參與運動的占比高達81.8%，但運動常伴著姿勢不佳導致的痠痛，促使民眾減少持續運動的意願。
        </div>
        <div class="content-desc">
          本實證設計姿勢評估檢測，藉由活動讓民眾透過鼓勵機制，報名並完成評估後開始使用課程，依循機制獲得抽獎機會，並減少姿態受限運動的機率。
        </div>
      </div>
      <div class="content-section">
        <div class="section-h3">數據貢獻活動</div>
        <div class="content-desc">
          優力勁聯、師子王智慧運動一同辦理「全民姿態評估GO」。
        </div>
        <div class="content-desc">
          於112年8月23日至11月30日，共完成累計1,500人次及2.4萬筆，其中包含運動頻率、消耗卡路里等運動生理數據，完整數據留存原始資料於AR動滋動XUniigymAPP中，並已與運動數據公益平台串接。
        </div>
      </div>
      <div class="content-section">
        <div class="section-h3">數據應用模式</div>
        <div class="content-desc">
          優力勁聯與運動服務業者蒐集體適能與生理數據，結合運動保健業者針對姿勢與線下評估，設計客製化運動健促課程。
        </div>
      </div>
      <div class="content-section">
        <div class="section-h3">數據應用效益</div>
        <div class="content-desc">
          對應運動保健市場需求，實證客製化運動保健商模，創造運動保健市場收益。
        </div>
      </div>
    </section>
  </el-main>
</template>

<script>
import MainContain from '@/components/Accessibility/MainContain.vue'

export default {
  name: 'UseCase11206',
  components: {
    MainContain
  },
  data () {
    return {
      image: require('@/assets/images/sample/112-06.jpg')
    }
  }
}
</script>

<style lang="scss" scoped>
@import "src/styles/index.scss";
.simple1-container{
  .inner-container{
    max-width: 800px;
    margin: 0 auto;
    padding: 1.25rem 0;
  }
  .image-container{
    padding: 1.5rem 0;
    .image {
      width: 80%;
      display: block;
      margin: 0 auto;
      }
    .image_desc{
      text-align: center;
    }
  }
  
  .content-section{
    
    .content-desc{
      line-height: 1.6;
    }
  }
}

</style>
